import {useHttpDelete} from "~/composables/useCustomFetch";
import { ModalsDecorDelete } from "#components";

export function useOrders() {
    const historyOrders = useState('historyOrders', () => [])
    const orders = useState('orders', () => [])
    const toast = useToast()
    const modal = useModal()
    const { refresh } = useAuth()
    const config = useRuntimeConfig()

    async function fetchOrders() {
        return useHttpGet('order')
            .then((response) => {
                orders.value = response.data.value
            })
    }

    async function removeDecorFromOrder(decorId, companyId) {
        let orderData = JSON.parse(JSON.stringify(orders.value.find(order => order.company_id === companyId)))

        if (orderData) {
            const index = orderData.items.findIndex(item => item.decor_id === decorId)

            if (index !== -1) {
                modal.open(ModalsDecorDelete, {
                    onSuccess () {
                        orderData.items.splice(index, 1)
                        if (orderData.items.length > 0) {
                            modal.close()
                            return addOrder(orderData)
                                .then((data) => {
                                    if (!data) {
                                        fetchOrders()
                                    } else {
                                        toast.add({ title: 'Товар удален из корзины!' })
                                    }
                                })
                        } else {
                            modal.close()
                            removeOrder(orderData.id)
                                .then((data) => {
                                    toast.add({ title: 'Товар удален из корзины!' })
                                })
                        }
                    },
                    onError() {
                        modal.close()
                    }
                })
            }
        }
    }

    async function setCountDecor(decorId, companyId, count) {
        let orderData = JSON.parse(JSON.stringify(orders.value?.find(order => order.company_id === companyId) ?? null))

        if (orderData) {
            const index = orderData.items.findIndex(item => item.decor_id === decorId)

            if (index !== -1) {
                orderData.items[index].quantity = count
                    return addOrder(orderData)
            }
        }
    }

    async function addDecorToOrder(decor, quantity, companyId, dateFrom = null, dateEnd = null) {
        let orderData = JSON.parse(JSON.stringify(orders.value?.find(order => order.company_id === companyId) ?? null))
        
        if (orderData) {
            const index = orderData.items.findIndex(item => item.decor_id === decor.id)
            if (dateFrom) {
                orderData.date_of_receiving = dateFrom
            }
            if (dateEnd) {
                orderData.date_return = dateEnd
            }
            
            if (index !== -1) {
                orderData.items[index].quantity = quantity
            } else {
                orderData.items.push({
                    "decor_id": decor.id,
                    "quantity": quantity
                })
            }
        } else {
            orderData = {
                "id":null,
                "address_delivery": "",
                "comment": "",
                "date_of_receiving": dateFrom,
                "date_return": dateEnd,
                "distance_delivery_all": 0,
                "distance_from_parking_to_building": 0,
                "from_time_date_return": null,
                "from_time_of_receiving": null,
                "items": [
                    {
                        "decor_id": decor.id,
                        "quantity": quantity
                    }
                ],
                "number_of_manual_lift_floors": 0,
                "punkt_delivery_km_outside_the_city": 0,
                "to_time_date_return": null,
                "to_time_of_receiving": null
            }
        }

        return addOrder(orderData)
            .then((response) => {
                if (response.status.value === 'success') {
                    let imageObject = null
                    if (Array.isArray(decor.images)) {
                        imageObject = decor.images[0]
                    } else {
                        imageObject = decor.images
                    }

                    if (imageObject) {
                        if (imageObject.path_photo.includes('static')) {
                            imageObject = config.public.apiUrl + imageObject.path_photo
                        } else {
                            imageObject = imageObject.path_photo
                        }
                    }
                    
                    toast.add({
                        title: decor.name,
                        description: decor.price + ' руб.',
                        avatar: { src: imageObject },
                        icon: null
                    })
                }
            })
    }

    async function addOrder(orderData) {
        return useHttpPost('order', {
            body: orderData
        }).then((response) => {
            if (response.status.value === 'error') {
                return
            }
            
            const orderInfo = response.data.value
            
            if (!orderInfo) {
                const index = orders.value.findIndex(order => order.id === orderData.id);

                if (index !== -1) {
                    orders.value.splice(index, 1);
                }
            } else {
                const index = orders.value.findIndex(order => order.id === orderInfo.id);

                if (index !== -1) {
                    orders.value.splice(index, 1, orderInfo);
                } else {
                    orders.value.push(orderInfo)
                }
            }
            
            return response
        })
    }

    async function getOrder(orderId) {
        return useHttpGet('order/' + orderId)
    }

    async function removeOrder(orderId) {
        return useHttpDelete('order/' + orderId)
            .then((response) => {
                const index = orders.value.findIndex(order => order.id === orderId);

                if (index !== -1) {
                    orders.value.splice(index, 1);
                }
            })
    }

    async function checkOrder(orderId) {
        return useHttpPut('order/' + orderId)
    }

    async function fetchHistory() {
        return useHttpGet('/order/history/')
            .then((response) => {
                historyOrders.value = response.data.value?.items ?? []
            })
    }

    async function getHistoryOrder(orderId) {
        return useHttpGet('order/history/' + orderId)
    }

    async function cancelHistoryOrder(orderId) {
        return useHttpPut('order/' + orderId + '/order-cancell')
            .then(() => {
                fetchHistory()
                refresh()
            })
    }

    async function restoreHistoryOrder(orderId) {
        return useHttpPut('order/' + orderId + '/order-restore')
            .then(() => {
                fetchHistory()
                refresh()
            })
    }


    async function updateHistoryOrder(orderId, orderData, save = false, ) {
        return useHttpPut('order/history/' + orderId + '/order-update?save=' + save,
            {
                body: orderData
            })
    }

    return {
        orders,
        historyOrders,
        fetchOrders,
        addOrder,
        getOrder,
        removeDecorFromOrder,
        addDecorToOrder,
        removeOrder,
        fetchHistory,
        setCountDecor,
        checkOrder,
        getHistoryOrder,
        updateHistoryOrder,
        cancelHistoryOrder,
        restoreHistoryOrder
    }
}
